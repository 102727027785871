@use "/src/styles/variables" as v;

.mobile-apps-container {
  .main-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .side-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    gap: 30px;

    .brand-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      font-size: 14px;
      margin-bottom: 10px;
      gap: 5px;
      text-align: center;

      img {
        width: 200px;
      }
    }

    .auth-form-side-item {
      display: flex;
      align-items: center;
      gap: 30px;
      width: 100%;

      .auth-form-side-item-icon {
        background-color: v.$color5-light;
        border-radius: 50%;
        padding: 5px;
      }

      .auth-form-side-item-text {
        font-size: 16px;
        color: v.$color2;
      }

      @media screen and (max-width: 991px) {
        padding-left: 0;
        gap: 20px;
      }
    }
  }
}