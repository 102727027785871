@use '../../../../styles/variables' as v;

.profile-page-container {

  .p-p-tabs {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;

    .nav-item {

      button.nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 16px;
        color: v.$color2;
        gap: 7px;
        border: 0;
        border-radius: 10px;
        position: relative;
        margin-bottom: 5px !important;

        &.active {
          background-color: v.$color10 !important;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -6px;
            width: 100%;
            height: 3px !important;
            background-color: v.$color-black !important;
          }
        }

        p {
          margin: 0;

          @media screen and (max-width: 991px) {
            display: none;
          }
        }

        svg {
          font-size: 20px;
        }

        &:hover {
          background-color: v.$color12;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -6px;
            width: 100%;
            height: 1px;
            background-color: v.$color2;
          }
        }

        &:active {
          background-color: v.$color11;
        }
      }
    }
  }

  .tab-content {
    .tab-pane {
      padding: 10px;
    }
  }


  //-------------<< Styling of Profile Forms >>-------------//

  .profile-form-container {
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid v.$color12;

    .profile-form-main {
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: rgba(v.$color10, 0.9);
      padding: 10px;
      gap: 30px;

      .form-wrapper {
        padding: 20px 30px;

        @media screen and (max-width: 767px) {
          padding: 10px 20px;
        }

        @media screen and (max-width: 575px) {
          padding: 0px 10px;
        }

        .profile-form {

          .input-group {
            position: relative;
            gap: 1px;

            .input-group-text {
              border-radius: 5px 0 0 5px;
              background-color: v.$color8;
              color: v.$color-white;
              border: 1px solid v.$color8;
              transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

              &.invalid {
                border-color: v.$color-error-light;
                background-color: v.$color-error-light;
                color: v.$color-white;
              }
            }

            .user-input {
              padding: 8px;
              border-radius: 0 5px 5px 0;
            }

            .form-feedback {
              position: absolute;
              bottom: -20px;
              left: 0px;
            }
          }

          .form-submit-button {
            display: flex;
            justify-content: center;
            align-items: center;

            .submit-button {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              width: 60%;
              border-radius: 5px;
              font-weight: 500;
              font-size: 14px;
              letter-spacing: 1px;
              padding: 10px;
            }
          }
        }
      }

    }

    .profile-form-side {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      padding: 30px;
      background-color: rgba(v.$color12, 0.7);
      gap: 30px;


      .profile-form-side-item {
        display: flex;
        align-items: center;
        gap: 30px;
        width: 100%;

        .profile-form-side-item-icon {
          background-color: v.$color5-light;
          border-radius: 50%;
          padding: 5px;
        }

        .profile-form-side-item-text {
          font-size: 16px;
          color: v.$color2;
        }

        @media screen and (max-width: 991px) {
          padding-left: 25%;
          gap: 20px;
        }

        @media screen and (max-width: 767px) {
          padding-left: 20%;
        }

        @media screen and (max-width: 575px) {
          padding-left: 0;
          gap: 10px;

          .profile-form-side-item-text {
            font-size: 14px;
          }
        }
      }
    }

  }

}