@use "../../../../styles/variables" as v;

.admin-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  min-width: 200px;
  background-color: v.$color5-light;
  border-radius: 10px;

  .admin-reset-title {
    text-align: center;

    svg {
      margin-bottom: 10px;

      path {
        transition: color 0.3s ease;
      }

      &.danger {
        path:last-child {
          color: v.$color-error-dark;
        }
      }
    }

    .reset-label {
      font-size: 20px;
    }
  }

  .reset-text {
    text-align: justify;
    width: 50%;


    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (max-width: 330px) {
      font-size: 14px;
    }
  }

  .reset-button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

    .reset-button {
      width: 150px;
      border-radius: 5px;
      letter-spacing: 1px;
      font-size: 14px;

      @media screen and (max-width: 330px) {
        width: 100%;
      }

      &.danger {
        background-color: v.$color-error-light;

        &:hover {
          background-color: v.$color-error-dark;
          color: v.$color-white;
        }

        &:active {
          background-color: v.$color-error-light;
          color: v.$color-white;
        }
      }
    }
  }

  .reset-input-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .reset-input-group {
      position: relative;
      text-align: center;
      width: 50%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .reset-input-label {
        position: relative;
        bottom: -8px;
        font-size: 14px;
      }

      .reset-input {
        border-radius: 5px;
        padding: 8px;

        &::placeholder {
          font-size: 14px;
          font-style: italic;
        }
      }
    }
  }
}