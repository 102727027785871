@use "../../../../styles/variables" as v;

.user-advert-edit-tour-request-container {
  h5 {
    font-weight: 600;
    color: v.$color1;
    margin-bottom: 20px;
  }

  .tr-datatable-wrapper {
    .card {
      border: none;
      border-radius: 10px;
      overflow: hidden;

      table {
        border-collapse: separate;
        border-spacing: 0 10px;

        thead tr {
          font-size: 16px;

          th {
            padding-bottom: 10px;
            background: none;

            .p-column-header-content {
              display: flex;
              justify-content: center;
            }
          }
        }

        tbody tr {
          font-size: 14px;

          &:nth-child(even) {
            td {
              background-color: v.$color6;
            }
          }

          &:nth-child(odd) {
            td {
              background-color: v.$color12;
            }
          }

          .p-tag {
            color: v.$color6;
            border-radius: 7px;
            padding: 3px;
            width: 120px;
            text-align: center;
            font-size: 12px;
            letter-spacing: 1px;
          }

          td {
            padding: 6px 0;

            &:first-child {
              border-radius: 10px 10px 0 0;
            }

            &:last-child {
              border-radius: 0 0 10px 10px;
            }

            @media screen and (min-width: 768px) {
              padding: 10px 0;

              &:first-child {
                border-radius: 10px 0 0 10px;
              }

              &:last-child {
                border-radius: 0 10px 10px 0;
              }

              &:not(:first-child) {
                div {
                  display: flex;
                  justify-content: center !important;
                  align-items: center;
                }
              }
            }

            .operationsButtons {
              display: flex;
              gap: 5px;

              @media screen and (max-width: 991px) {
                flex-flow: wrap;
              }

              .btn-link {
                color: white;
                background-color: v.$color8;
                border: 1px solid v.$color8;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 50%;

                &:first-child {
                  &:hover {
                    border-color: v.$color-error-dark;
                    background-color: v.$color-error-light;
                  }
                }

                &:last-child {
                  &:hover {
                    border-color: v.$color-success-dark;
                    background-color: v.$color-success-light;
                  }
                }

                svg {
                  height: 16px;
                  width: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}