@use "../../../../styles/variables" as v;

.contact-message-filter-modal {
  .modal-body {
    // text-align: center;

    .modal-button-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      .modal-button {
        background-color: v.$color5-light;
        border-radius: 50%;
        padding: 5px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        color: v.$color1;

        &:hover {
          color: v.$color12;
        }

        &.done-button {
          &:hover {
            background-color: v.$color-success-light;
          }
        }

        &.no-filter-button {
          &:hover {
            background-color: v.$color-error-light;
          }
        }
      }
    }

    .filter-form-group {
      margin-bottom: 10px;

      .form-label {
        font-size: 14px;
        margin: 0;
      }

      .form-control {
        border: none;
        border-bottom: 1px solid v.$color2;
      }

      select.form-control {
        cursor: pointer;
      }
    }


  }
}