@use "../../styles/variables.scss" as v;

.password-input-group {
    position: relative;
    gap: 1px;

    .password-input-group-text {
        border-radius: 5px 0 0 5px;
        background-color: v.$color8;
        color: v.$color-white;
        border: 1px solid v.$color8;
        transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

        &.invalid {
            border-color: v.$color-error-light;
            background-color: v.$color-error-light;
            color: v.$color-white;
        }
    }

    .user-password-input {
        padding: 8px;
        border-radius: 0;
    }

    .password-eye {
        border-radius: 0 5px 5px 0 !important;
        background-color: v.$color8;
        color: v.$color-white;
        border: 1px solid v.$color8;
        overflow: hidden;
        transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
        cursor: pointer;

        &.invalid {
            border-color: v.$color-error-light;
            background-color: v.$color-error-light;
            color: v.$color-white;
        }
    }

    .form-feedback {
        position: absolute;
        bottom: -20px;
        left: 0px;
    }
}