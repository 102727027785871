@use "/src/styles/variables" as v;

.homepage-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, v.$color12, v.$color11);
  background-size: cover;
  padding: 40px;
  border-radius: 10px;
  height: 500px;
  position: relative;

  @media screen and (max-width: 991px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../../../../public/images/content/background-narrow.jpg");
    background-size: cover;
    max-width: 100% !important;
    border-radius: 0px;
  }

  .banner-images {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    border-radius: 0 10px 10px 0;
    height: 500px;
    width: 45%;
    overflow: hidden;
    pointer-events: none;

    .swiper-slide {
      pointer-events: none;

      img {
        display: none;
        opacity: 0;
      }
    }

    .swiper-slide-active {
      img {
        display: block;
        opacity: 1;
        animation: scaleAnimation 21s infinite;

        @keyframes scaleAnimation {
          0% {
            transform: scale(1);
          }

          100% {
            transform: scale(1.25);
          }
        }
      }
    }

    .banner-slide-image {
      position: absolute;
      width: 100%;
      bottom: 0;
      right: 0;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding-left: 50px;
    z-index: 1;

    @media screen and (max-width: 991px) {
      align-items: center;
      padding-left: 0;

      .headline {
        text-align: center;
        color: v.$color-white !important;
      }

      .search-panel {
        width: 90% !important;
      }
    }

    .headline {
      display: flex;
      flex-direction: column;
      font-size: 24px;
      color: v.$color2;

      h3:nth-child(2) {
        font-size: 86px;
        color: v.$color1;
        font-weight: 900;
        line-height: 84px;
        padding-top: 10px;

        @media screen and (max-width: 991px) {
          padding-top: 0;
          font-size: 60px;
        }
      }

      h3:nth-child(3) {
        letter-spacing: 2px;
      }
    }

    .search-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 60%;
      //LG start

      .advert-type-wrapper {
        display: flex;
        gap: 10px;
        padding: 0 0 6px 20px;

        button {
          text-decoration: none;
          border-radius: 5px;
          font-size: 14px;
          letter-spacing: 1px;
          font-weight: 500;
          color: v.$color2;
          position: relative;
          padding: 3px 9px;
          transition: background-color 0.3s ease, color 0.3s ease;

          &:hover {
            background-color: v.$color10;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -6px;
              width: 100%;
              height: 1px !important;
              background-color: v.$color-black !important;
            }
          }

          &:active {
            background-color: v.$color9;
          }

          &.selected {
            color: v.$color-white;
            background-color: v.$color9;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -6px;
              width: 100%;
              height: 3px !important;
              background-color: v.$color-black !important;
            }
          }
        }
      }

      .search-collapse-wrapper {
        width: 100%;
        border-radius: 10px;
        border: 1px solid v.$color5-light;
        background-color: v.$color-white;

        .search-input-wrapper {
          display: flex;
          padding: 20px;
          gap: 10px;
          border-radius: 10px;

          input {
            background-color: v.$color5-light;
            border-radius: 10px;
            border: none;
          }

          button {
            padding: 5px;
            border-radius: 10px;
          }
        }

        .type-category-drop-wrapper {
          display: flex;
          background-color: v.$color5-light;
          border-radius: 0 0 10px 10px;

          .type-dropdown,
          .category-dropdown {
            width: 50%;
            background-color: v.$color5-light;
            border-top: 1px solid lightgray;

            button {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: v.$color5-light;
              border: none;
              padding: 5px;
              font-size: 14px;
            }
          }

          .type-dropdown {
            border-right: 1px solid lightgray;
            border-bottom-left-radius: 10px;

            button {
              border-bottom-left-radius: 10px;
            }
          }

          .category-dropdown {
            border-bottom-right-radius: 10px;

            button {
              border-bottom-right-radius: 10px;
            }
          }

          .dropdown-menu {
            width: 100%;
            border-radius: 10px;
            padding: 0;
            overflow: hidden;
            margin-top: 8px;

            .dropdown-item {
              &:hover {
                background-color: v.$color4-light;
              }

              &:active {
                background-color: v.$color4;
                color: v.$color-black;
              }

              &.active {
                background-color: v.$color1;
              }
            }
          }
        }
      }

      .category-wrapper {
        display: flex;
        gap: 10px;
        padding: 6px 0 0 20px;

        button {
          text-decoration: none;
          border-radius: 5px;
          font-size: 14px;
          letter-spacing: 1px;
          font-weight: 500;
          color: v.$color2;
          position: relative;
          padding: 3px 9px;
          transition: background-color 0.3s ease, color 0.3s ease;

          &:hover {
            background-color: v.$color10;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: -6px;
              width: 100%;
              height: 1px !important;
              background-color: v.$color-black;
            }
          }

          &:active {
            background-color: v.$color9;
          }

          &.selected {
            color: v.$color-white;
            background-color: v.$color9;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: -6px;
              width: 100%;
              height: 3px !important;
              background-color: v.$color-black;
            }
          }
        }
      }
    }
  }
}