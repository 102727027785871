@use "../../../../styles/variables" as v;

.admin-user-logs-container {

  .tr-datatable-wrapper {
    .card {
      border: none;
      border-radius: 10px;
      overflow: hidden;

      table {
        border-collapse: separate;
        border-spacing: 0 10px;

        thead tr {
          font-size: 16px;

          th {
            padding-bottom: 10px;
            background: none;

            &:first-child {
              padding-left: 65px;
            }

            &:last-child {
              display: flex;
              justify-content: center;
              margin-left: 10px;
            }
          }
        }

        tbody tr {
          font-size: 14px;

          &:nth-child(even) {
            td {
              background-color: v.$color6;
            }
          }

          &:nth-child(odd) {
            td {
              background-color: v.$color12;
            }
          }

          td {
            padding: 6px 0;

            &:first-child {
              border-radius: 10px 10px 0 0;

              .log-message {
                text-align: end;
              }
            }

            &:last-child {
              border-radius: 0 0 10px 10px;
              width: 100%;
            }

            @media screen and (min-width: 768px) {
              padding: 10px 0;

              &:first-child {
                border-radius: 10px 0 0 10px;

                .log-message {
                  text-align: start;
                }
              }

              &:last-child {
                border-radius: 0 10px 10px 0;
                width: 150px;
              }
            }
          }
        }
      }
    }
  }
}