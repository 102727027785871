@use "../../../../styles/variables" as v;

.advert-details-tour-request-container {
  background-color: v.$color11;
  border-radius: 10px;
  padding: 10px !important;
  box-shadow: 0 0 4px 1px v.$color5;
  height: 380px;

  .advert-tour-request-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .form-header {
      text-align: center;
      margin-bottom: 10px;
    }

    .tour-request-tip {
      text-align: justify;
      margin: 0;
      font-size: 14px;
    }

    .tour-request-form-group-wrapper {
      .advert-tour-request-form-group {
        position: relative;

        .form-label {
          margin-bottom: 0;
        }

        .form-control {
          border: 1px solid v.$color5;
          padding: 8px auto;
        }

        .form-feedback {
          bottom: -20px;
          font-size: 14px;
          position: absolute;
        }
      }
    }

    .tour-request-form-button-wrapper {
      display: flex;
      justify-content: center;


      button {
        width: 96%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        gap: 5px;

        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}