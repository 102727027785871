@use "../../../../styles/variables" as v;

.admin-category-common-container {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(v.$color5, 0.7);
    overflow: hidden;
    padding: 3px;

    .admin-category-common-form {
        .category-common-element-wrapper {
            &.category-status {
                display: flex;
                justify-content: center;

                .category-switch {
                    font-size: 30px;
                }
            }

            @media screen and (max-width: 575px) {
                padding: 0;
            }
        }

        .admin-category-common-button-wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 10px;
            padding: 7px;
            gap: 10px;

            @media screen and (max-width: 767px) {
                flex-direction: column-reverse;
            }

            .category-common-button {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                letter-spacing: 1px;
                font-size: 14px;
                width: 50%;
                max-width: 200px;
                padding: 10px 5px;
                border-radius: 5px;

                @media screen and (max-width: 767px) {
                    width: 100%;
                }

                &.delete-button {
                    background-color: v.$color-error-light;

                    &:hover {
                        background-color: v.$color-error-dark;
                        color: v.$color-white;
                    }

                    &:active {
                        background-color: v.$color-error-light;
                        color: v.$color-white;
                    }
                }
            }
        }
    }
}