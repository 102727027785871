@use "../../../../styles/variables" as v;

.icon-picker-container {
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 2rem;

    .displayer-wrapper {
        display: flex;
        align-items: center;
        position: relative;

        .icon-tip {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: absolute;
            gap: 3px;
            top: -5px;
            font-size: 14px;

            @media screen and (max-width: 575px) {
                top: 5px;
            }

            &:hover {
                cursor: pointer;

                span {
                    text-decoration: underline;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .selected-icon-displayer {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid v.$color2;
            height: 80px;
            width: 80px;
            min-width: 80px;
            border-radius: 5px;
            overflow: hidden;

            &.error {
                border: 1px solid v.$color-error-light;
            }

            @media screen and (max-width: 575px) {
                height: 64px;
                width: 64px;
                min-width: 64px;
            }

            .selected-icon {
                padding: 5px;
                height: 100%;
                width: 100%;
            }

            .p-skeleton {
                border-radius: 0px;
            }

            svg {
                height: 100%;
                width: 100%;
            }
        }
    }

    .icon-picker-input-wrapper {
        flex: 1;

        .icon-input-group {
            position: relative;

            .icon-input {
                padding: 15px;
                border-radius: 0 5px 5px 0;
                border-left: 0 !important;
                background-color: v.$color5-light;

                &:focus {
                    box-shadow: none;
                }
            }

            .formik-feedback {
                position: absolute;
                left: 3px;
            }
        }
    }
}