@use "../../../../styles/variables" as v;

.tour-request-update-container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  .advert-status-info {
      display: flex;
      gap: 10px;

      .title-address {
          width: 70%;

          .title,
          .address {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
          }

          .address {
              font-size: 14px;
          }
      }

      .tour-status {
          .tour-request-tag {
              font-size: 12px;
              padding: 2px 5px;
              letter-spacing: 1px;
          }
      }

      .price {
          width: 30%;
          text-align: end;
          font-weight: 500;
      }

      @media screen and (max-width: 991px) {
          flex-direction: column;
          gap: 0;

          .title-address {
              width: 100%;
          }

          .price {
              width: 100%;
              text-align: start;
          }
      }
  }

  .tour-date-time {
      display: flex;
      align-items: center;
      gap: 10px;

      .form-group-date,
      .form-group-time {
          width: 50%;

          .form-control {
              font-size: 16px;
              border: 0;
              border-bottom: 2px solid v.$color7;

              @media screen and (max-width: 575px) {
                  font-size: 14px;
              }

              &:active,
              &:focus {
                  box-shadow: 0 0 4px v.$color9;
              }
          }

          .form-text {
              color: v.$color2;
              text-align: center;
              margin-bottom: 500px;
          }
      }
  }

  .button-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        letter-spacing: 1px;
        font-size: 14px;
        width: 50%;
        max-width: 200px;
        padding: 10px 5px;
        border-radius: 5px;
    }
}


  // padding: 25px;
  // max-width: 650px;

  // .tour-request-update-form {
  //   .tour-request-update-row-title {
  //     width: 100%;
  //     display: flex;
  //     justify-content: space-between;

  //     .tour-request-update-col-title {
  //       color: v.$color7;
  //       flex: 0 0 70%;
  //       text-align: start;
  //     }

  //     .tour-request-update-col-price {
  //       color: v.$color7;
  //       flex: 0 0 30%;
  //       text-align: end;
  //       font-weight: 800;
  //     }
  //   }

  //   .tour-request-update-col-location {
  //     color: v.$color8;
  //     margin-bottom: 0;
  //   }

  //   .tour-request-input-row {
  //     margin-left: 0;
  //     display: flex;
  //     justify-content: center;
  //     width: 100%;

  //     .tour-request-input-col {
  //       width: 100%;
  //       min-width: 150px;
  //       padding: 0;
  //       display: flex;
  //       justify-content: space-between;
  //       margin-right: 0;

  //       .tour-request-input-date {
  //         border-bottom: 2px solid v.$color7;
  //         width: 90%;

  //         .form-control {
  //           border: 0;

  //           &:active,
  //           &:focus {
  //             box-shadow: 0 0 4px v.$color9;
  //           }
  //         }
  //       }

  //       .tour-request-input-time {
  //         width: 90%;
  //         border-bottom: 2px solid v.$color7;

  //         .form-control {
  //           border: 0;

  //           &:active,
  //           &:focus {
  //             box-shadow: 0 0 4px v.$color9;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .tour-request-update-button-row {
  //     max-width: 80%;
  //     padding: 20px;
  //     min-width: 150px;

  //     .tour-request-update-button-col {
  //       text-align: end;

  //       .tour-request-update-button-calcel {
  //         width: 100%;
  //         border-radius: 7px;
  //         background-color: v.$color9;
  //         border: 0;
  //         color: v.$color7;
  //         font-size: 1.3rem;
  //         padding: 2px;
  //         min-width: 80%;

  //         &:active,
  //         &:focus,
  //         &:hover {
  //           box-shadow: 0 0 3px v.$color8;
  //         }
  //       }

  //       .tour-request-update-button-update {
  //         width: 100%;
  //         border-radius: 7px;
  //         background-color: v.$color4;
  //         border: 0;
  //         color: v.$color7;
  //         font-size: 1.3rem;
  //         padding: 2px;
  //         min-width: 80%;

  //         &:active,
  //         &:focus,
  //         &:hover {
  //           box-shadow: 0 0 3px v.$color1;
  //         }
  //       }
  //     }
  //   }
  // }
}