@use "../../../../styles/variables" as v;

.advert-image {
  width: 100% !important;
  padding: 0 !important;



  .image-upload {
    position: relative;

    .plus-button {
      outline: 2px solid v.$color1;
      animation: outlineAnimation 1s infinite;
      background-color: v.$color1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      color: white;
      z-index: 1;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      position: absolute;
    }

    .upload-area {
      min-height: fit-content;
      border: 2px dashed #ccc;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding: 30px;

      .upload-area-inner-text {
        margin: 0;
        padding: 50px 0;
      }

      .img-wrapper {
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        overflow: hidden;
        border-radius: 8px;

        img {
          object-fit: cover;
          width: 100%;
          background-position: center;
          transition: all 0.7s ease;
        }

        .checkbox-wrapper {
          .checkmark {
            cursor: pointer;
            position: absolute;
            inset: 0;
            height: 32px;
            width: 32px;
            background-color: #eee;
            border-radius: 50%;
          }

          &:hover input~.checkmark {
            background-color: #ff9080;
          }

          input:checked~.checkmark {
            background-color: #b81902;
          }

          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          input:checked~.checkmark:after {
            display: block;
          }

          .checkmark:after {
            left: 8px;
            top: 7px;
            content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="18" width="16" viewBox="0 0 448 512"%3E%3Cpath d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" fill="%23ffffff" /%3E%3C/svg%3E');
            color: white;
            font-size: 2px;
          }
        }
      }
    }

    .file-label {
      position: absolute;
    }

    input {
      display: none;
    }

    .image-feedback {
      margin-top: 20px;
      color: red;
      position: absolute;
    }
  }
}

@keyframes outlineAnimation {
  0% {
    outline-offset: -1px;
  }

  25% {
    outline-offset: 3px;
  }

  50% {
    outline-offset: 5px;
  }

  75% {
    outline-offset: 3px;
  }

  100% {
    outline-offset: -1px;
  }
}