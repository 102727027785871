@use "../../../../styles/variables" as v;

.advert-details-image-container {
  display: flex;
  justify-content: center;
  padding: 0 !important;
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;

  .galleria {
    width: 100%;

    .p-galleria-content {
      background-color: v.$color5;

      .p-galleria-item-wrapper {
        position: relative;

        .p-galleria-item-container {
          .p-galleria-item {
            .shown-image-wrapper {
              position: unset;

              img {
                max-width: 100%;
                height: 400px;
                object-fit: cover;
                pointer-events: none;
              }

              .p-image-preview-indicator {
                position: absolute;
                top: 342px;
                left: 10px;
                opacity: 1;
                height: 50px;
                width: 50px;
                background-color: v.$color2-light;
                border-radius: 5px;

                &:hover {
                  background-color: v.$color2;
                }
              }
            }
          }
        }
      }

      .p-galleria-thumbnail-wrapper {

        .p-galleria-thumbnail-container {
          padding: 5px;
          gap: 5px;

          .p-galleria-thumbnail-prev,
          .p-galleria-thumbnail-next {
            border-radius: 50%;
          }

          .p-galleria-thumbnail-items {
            gap: 5px;

            .p-galleria-thumbnail-item {
              .p-galleria-thumbnail-item-content {
                padding: 10px 0;
              }

              &.p-galleria-thumbnail-item-current {
                .thumbnail-image {
                  box-shadow: 0px 0px 10px 1px rgba(v.$color1, 0.3), 0px 0px 6px 0px rgba(v.$color1, 0.7);
                }
              }

              .thumbnail-image {
                width: 160px;
                height: 90px;
                object-fit: cover;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
}