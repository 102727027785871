@use "../../styles/variables" as v;

.page-header {
  display: flex;
  align-items: center;
  background-image: linear-gradient(to left, v.$color4, v.$color4-light);
  padding: 30px 0;
  margin-top: 25px;
  border-radius: 10px;

  h1 {
    margin: 0;
    font-size: 32px;
    margin-left: 20px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0;
    margin-top: 15px;


    h1 {
      font-size: 24px;
      margin-left: 10px;
    }
  }
}