.error-page-container {
    .error-page-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;


        img {
            object-fit: cover;
            object-position: center;
            max-width: 100%;
        }

        .error-page-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 20px;
            margin-bottom: 20px;

            .error-button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px;
                flex-shrink: 0;
                border-radius: 50%;

                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}