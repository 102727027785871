@use "../../../../styles/variables" as v;

.admin-adverts-edit-page {
    .admin-advert-edit-button-wrapper {
        display: flex;
        justify-content: center;
        gap: 10px;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }

        .delete-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            padding: 15px;
            width: 270px;
        }
    }
}