@use '../../styles/variables' as v;

.password-suggestions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    padding: 30px;
    height: 100%;
    width: 100%;
    background-color: rgba(v.$color12, 0.7);

    .progress-bar-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .strength-label {
            font-size: 14px;
        }

        .password-strength-bar {
            border-radius: 7px;
            background-color: v.$color5;

            &.none .progress-bar {
                background-color: v.$color-error-light;
            }

            &.bad .progress-bar {
                background-color: v.$color-warning-light;
            }

            &.weak .progress-bar {
                background-color: v.$color-warning-dark;
            }

            &.average .progress-bar {
                background-color: v.$color-info-light;
            }

            &.strong .progress-bar {
                background-color: v.$color-success-light;
            }
        }
    }

    .suggestion {
        display: flex;
        align-items: center;
        gap: 10px;
        color: v.$color2;
        font-size: 14px;

        svg {
            font-size: 16px;
        }

        &.fulfilled {
            color: v.$color-success-light;
        }
    }

}