@use "../../../../styles/variables" as v;

.advert-details-description-container {
  background-color: v.$color5-light;
  border-radius: 10px;
  padding: 0 !important;
  margin-top: 30px;
  box-shadow: 0 0 4px 0 v.$color5;
  min-width: 200px;
  overflow: hidden;

  .description-title {
    color: v.$color-black;
    font-size: 15px;
    letter-spacing: 1px;
    background-color: v.$color5;
    padding: 10px;
  }

  .advert-category-title {
    font-size: 15px;
    padding: 10px;
  }

  .advert-description {
    font-size: 15px;
    text-align: justify;
    word-wrap: break-word;
    padding: 10px;
  }
}