@use "../../../../styles/variables" as v;


.display-image {

    .display-wrapper {
        position: relative;


        .display-area {
            padding: 30px;
            margin: 0;
            position: relative;
            display: flex;
            min-height: fit-content;
            justify-content: center;
            align-items: center;
            border: 2px solid v.$color5;
            border-radius: 8px;
            background-color: v.$color-white;
            // box-shadow: 0 1px 10px 0 v.$color5 inset;
            box-shadow: inset 0px 2px 10px 0px rgba(v.$color-black, 0.2);

            .image-col {
                overflow: hidden;

                .img-wrapper {
                    height: 100px;
                    width: 100%;
                    position: relative;
                    margin: 10px 0 20px 0;


                    &.featured {
                        .checkbox-wrapper {
                            border: 2px solid v.$color1;
                            border-bottom: none;

                            img {
                                border-radius: 6px 6px 0 0;
                            }

                            &::after {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                content: "FEATURED";
                                font-size: 12px;
                                letter-spacing: 2px;
                                position: absolute;
                                left: auto;
                                bottom: 0;
                                width: 100%;
                                background-color: v.$color1;
                                transform: translateY(100%);
                                background-color: v.$color1;
                                color: v.$color5-light;
                                padding: 0px;
                                border-radius: 0 0 8px 8px;
                            }
                        }
                    }

                    .checkbox-wrapper {
                        height: 100px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        border-radius: 8px 8px 0 0;
                        cursor: pointer;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                            background-position: center;
                            border-radius: 6px;
                        }
                    }

                    .checkmark {
                        cursor: pointer;
                        position: absolute;
                        top: -10px;
                        left: -10px;
                        height: 32px;
                        width: 32px;
                        background-color: #eee;
                        border-radius: 50%;
                    }

                    &:hover input~.checkmark {
                        background-color: #ff9080;
                    }

                    input:checked~.checkmark {
                        background-color: #b81902;
                    }

                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    input:checked~.checkmark:after {
                        display: block;
                    }

                    .checkmark:after {
                        left: 8px;
                        top: 7px;
                        content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="18" width="16" viewBox="0 0 448 512"%3E%3Cpath d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" fill="%23ffffff" /%3E%3C/svg%3E');
                        color: white;
                        font-size: 2px;
                    }

                }
            }
        }

        input {
            display: none;
        }

        .image-feedback {
            color: red;
            position: absolute;
        }
    }

    .display-image-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        width: 100%;

        .button-wrapper {
            display: flex;
            width: 270px;
            gap: 20px;

            @media screen and (max-width: 767px) {
                width: 100%;
            }

            @media screen and (max-width: 575px) {
                // width: 100%;
                flex-direction: column;
            }

            button {
                flex: 1;
                padding: 15px;

                @media screen and (max-width: 767px) {
                    font-size: 14px;
                    padding: 15px 5px;
                }
            }
        }
    }

}