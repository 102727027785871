@use "/src/styles/variables" as v;

.city-statistics {

  h5 {
    color: v.$color1;
  }

  .city-swiper {
    padding: 10px;
    padding-bottom: 20px;

    .cities-slider {
      display: flex;
      justify-content: center;
      align-items: center;

      .by-cities-card {
        color: white;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          box-shadow: 0 0 10px 2px rgba(v.$color7, 0.3), 0 0 5px 1px rgba(v.$color7, 0.4);
          transform: scale(1.02);
        }

        &,
        .by-cities-card-img {
          border-radius: 10px;
          width: 260px;
          height: 300px;
          object-fit: cover;
          text-align: center;
        }

        .statistics {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border-radius: 10px;
          color: white;
          font-size: 16px;
          background: linear-gradient(to top, #000000a8 0%, #00000000 60%);
          background-color: rgba(v.$color1, 0.1);

          h6 {
            margin: 0;
          }
        }
      }
    }
  }
}